import React, {useEffect, useRef, useState} from 'react'
import image1 from './wedding1.jpg'
import image2 from './wedding3.jpg'
import './Home.css'

export default function Home() {
 
  return (
    <section id='home' style={{backgroundImage: `url(${image1})`, height: '100vh', backgroundPosition: 'center', backgroundSize: 'cover'}}>
   
    </section>
  )
}
